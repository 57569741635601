import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Input } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<Input placeholder=\"Placeholder\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input placeholder="Placeholder" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "states"
    }}>{`States`}</h2>
    <Playground __position={1} __code={'<Input mb={2} />\n<Input placeholder=\"Placeholder\" mb={2} />\n<Input value=\"This is a value\" mb={2} />\n<Input disabled value=\"Disabled\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input mb={2} mdxType="Input" />
  <Input placeholder="Placeholder" mb={2} mdxType="Input" />
  <Input value="This is a value" mb={2} mdxType="Input" />
  <Input disabled value="Disabled" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "alternative"
    }}>{`Alternative`}</h2>
    <p>{`In flows with only a few fields you might use the prop `}<inlineCode parentName="p">{`alternative`}</inlineCode>{` to get a gray input field that stands out more.`}</p>
    <Playground __position={2} __code={'<Input alternative placeholder=\"Alternative\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input alternative placeholder="Alternative" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "interactive"
    }}>{`Interactive`}</h2>
    <p>{`Give the input field a hover effect by passing `}<inlineCode parentName="p">{`interactive`}</inlineCode>{`. Usually used together with click-to-edit functionality.`}</p>
    <Playground __position={3} __code={'<Input interactive />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input interactive mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "overriding"
    }}>{`Overriding`}</h2>
    <p>{`Color, layout and border properties can be overriden.`}</p>
    <Playground __position={4} __code={'<Input bg=\"neutral.4\" borderColor=\"neutral.5\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Input,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Input bg="neutral.4" borderColor="neutral.5" mdxType="Input" />
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Input} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      